import { GrowthBookProvider } from '@/utils/growthbook/GrowthBookProvider'
import { GrowthBookPlugin } from '@/utils/growthbook/types'
import { AuthenticationContext, PrincipalIdentity } from '@/iam/types'
import { GrowthBook } from '@growthbook/growthbook'
import { App } from 'vue'

export const growthbookInstance = (function () {
  let _authenticationContext: AuthenticationContext
  let _growthbook: GrowthBook

  const setAuthenticationContext = (authContext: AuthenticationContext): void => {
    _authenticationContext = authContext
  }

  const intializeGrowthbook = async (clientKey: string, enableDevMode: boolean) => {
    try {
      _growthbook = new GrowthBook({
        apiHost: 'https://cdn.growthbook.io',
        clientKey: clientKey,
        enableDevMode,
      })

      const user: PrincipalIdentity | null = _authenticationContext ? _authenticationContext.getUser() : null

      if (_authenticationContext && user) {
        _growthbook.setAttributes({
          loggedIn: true,
          id: user.id,
          email: user.email,
          userAgent: window.navigator.userAgent,
        })
      } else {
        _growthbook.setAttributes({
          loggedIn: false,
          userAgent: window.navigator.userAgent,
        })
      }

      await _growthbook.loadFeatures()

      return _growthbook
    } catch (e) {
      console.error('GrowthBook Vue plugin error', e)
      return null
    }
  }

  const createGrowthbookClient = async (
    options: { clientKey: string; enableDevMode: boolean },
    authenticationContext: AuthenticationContext,
  ): Promise<GrowthBookPlugin> => {
    setAuthenticationContext(authenticationContext)
    await intializeGrowthbook(options.clientKey, options.enableDevMode)
    return {
      install: (app: App): void => {
        app.config.globalProperties.$growthbook = _growthbook
        app.provide('growthbook', _growthbook)
        const growthBookInstance: () => GrowthBook = () => _growthbook
        GrowthBookProvider.setInstance(growthBookInstance)
      },
    }
  }

  const createDefaultGrowthbookClient = async (options: { clientKey: string; enableDevMode: boolean }) => {
    await intializeGrowthbook(options.clientKey, options.enableDevMode)
    return {
      install: (app: App): void => {
        app.config.globalProperties.$growthbook = _growthbook
        app.provide('growthbook', _growthbook)
      },
    }
  }
  return {
    createGrowthbookClient,
    createDefaultGrowthbookClient,
  }
})()
