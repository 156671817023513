<script setup lang="ts">
import { Ref, computed, onBeforeMount, ref } from 'vue'
import { RouterView, useRoute } from 'vue-router'

import Modal from '@/components/Modal.vue'
import { useModal } from '@/modal/useModal'

const { isOpen } = useModal()
/**
 * Convert the configured component names in the router to a set.
 */
const components = computed(() => {
  const route = useRoute()
  // flatmap out the names of the components needed.
  // pop them into a Set<> for uniquenes.
  return new Set(route.matched.flatMap((c) => (c?.components ? Object.keys(c.components) : [])))
})

onBeforeMount(() => {
  // read from browser setting of dark theme and move to session storage.
  const storageValue = sessionStorage.getItem('prefers-color-scheme')
  if (storageValue === 'dark' || window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.classList.add('dark')
  }
})

// sidebar expand collapse
const isSidebarExpanded: Ref<boolean> = ref(false)
const toggleSidebar = (forceExpand?: boolean) => {
  isSidebarExpanded.value = forceExpand || !isSidebarExpanded.value
}
</script>

<template>
  <Modal v-show="isOpen">
    <div id="modal-container"></div>
  </Modal>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-50">
    <header v-if="components.has('header')" id="header-content">
      <RouterView name="header" class="p-3" :expanded="isSidebarExpanded" @toggle-sidebar="toggleSidebar" />
    </header>
    <div class="flex overflow-hidden bg-gray-50 dark:bg-gray-900">
      <aside
        v-if="components.has('sidebar')"
        id="sidebar-content"
        class="flex bg-gray-50 dark:bg-gray-900 min-h-[calc(100vh_-_4.2rem)]"
      >
        <RouterView
          name="sidebar"
          :expanded="isSidebarExpanded"
          @mouseover="toggleSidebar(true)"
          @mouseout="toggleSidebar(false)"
        />
      </aside>
      <main v-if="components.has('main')" id="main-content" class="relative w-full flex flex-col mx-auto">
        <RouterView name="main" />
      </main>
    </div>
  </div>
</template>
