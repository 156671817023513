import { Ref, ref, readonly } from 'vue'

const isOpen: Ref<boolean> = ref(false)
const showCloseButton: Ref<boolean> = ref(true)
const canCloseOnBackdrop: Ref<boolean> = ref(true)

export const useModal = () => {
  const updateModalState = (value: boolean) => {
    isOpen.value = value
    if (!isOpen.value) {
      showCloseButton.value = true
      canCloseOnBackdrop.value = true
    }
  }

  const updateCloseButtonState = (show: boolean = true) => {
    showCloseButton.value = show
  }

  const updateCanCloseOnBackdropState = (canClose: boolean = true) => {
    canCloseOnBackdrop.value = canClose
  }

  return {
    isOpen: readonly(isOpen), // avoid the state to be mutated from a component
    showCloseButton,
    canCloseOnBackdrop,
    updateModalState,
    updateCloseButtonState,
    updateCanCloseOnBackdropState,
  }
}
